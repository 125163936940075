import { Link, useForm, usePage } from '@inertiajs/react';
import { Alert, Button, Input } from '../../../shared/components';
import { LoginLayout } from '../../layouts';

const LoginView = () => {
    const { flash, ssoProviders } = usePage().props;

    const { data, setData, post, processing, errors } = useForm({
        email: '',
        password: '',
    });

    const handleFormSubmit = e => {
        e.preventDefault();

        post('/login');
    };

    return (
        <div className='space-y-16'>
            <form onSubmit={handleFormSubmit} noValidate>
                <p className='mt-4 mb-11 text-lg'>Log in op jouw account</p>

                {flash?.status && <Alert message={flash.status} />}

                {flash?.message && (
                    <Alert message={flash.message} variant='error' />
                )}

                <Input
                    autoFocus={true}
                    autoComplete='email'
                    placeholder='E-mailadres'
                    type='email'
                    value={data.email || ''}
                    onChange={e => setData('email', e.target.value)}
                    error={errors?.email}
                />
                <Input
                    autoComplete='current-password'
                    placeholder='Wachtwoord'
                    type='password'
                    value={data.password || ''}
                    onChange={e => setData('password', e.target.value)}
                    error={errors?.password}
                />
                <div className='mb-5 flex justify-end'>
                    <Link
                        href='/forgot-password'
                        className='text-primary-500 hover:text-primary-200 transition-colors ease-in-out'
                    >
                        Wachtwoord vergeten
                    </Link>
                </div>
                <Button
                    content='Inloggen'
                    type='submit'
                    disabled={processing}
                    loading={processing}
                />
            </form>

            {ssoProviders.length > 0 && (
                <div>
                    <div className='relative'>
                        <div
                            aria-hidden='true'
                            className='absolute inset-0 flex items-center'
                        >
                            <div className='w-full border-t border-gray-200' />
                        </div>
                        <div className='relative flex justify-center text-sm/6 font-medium'>
                            <span className='bg-white px-6 text-gray-900'>
                                Of ga verder met
                            </span>
                        </div>
                    </div>

                    <div className='mt-6 grid grid-cols-2 gap-4'>
                        <a
                            href='/sso/microsoft/auth'
                            className='col-span-full flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 shadow-sm ring-gray-300 ring-inset hover:bg-gray-50 focus-visible:ring-transparent'
                        >
                            <img
                                src='/images/sso/microsoft.svg'
                                alt=''
                                className='size-5'
                                aria-hidden='true'
                            />
                            <span className='text-sm/6 font-medium'>
                                Microsoft
                            </span>
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

LoginView.layout = page => <LoginLayout>{page}</LoginLayout>;

export default LoginView;
