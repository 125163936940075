const LoginLayout = ({ children }) => (
    <div className='flex min-h-lvh flex-col bg-white'>
        <header className='from-primary-gradient-from to-primary-gradient-to bg-linear-to-br px-4 py-8'>
            <div className='f-full mx-auto max-w-[1400px]'>
                <img src='/images/vcsw.logo_light.svg' alt='VCSW Digitaal' />
            </div>
        </header>

        <main className='mx-auto w-full max-w-[1280px] px-4 pt-12 md:grid md:grid-cols-2'>
            <div>
                <h1 className='text-primary-950 text-5xl font-bold'>
                    VCSW <span className='text-primary-500'>Digitaal</span>
                </h1>
                <div className='mr-0 md:mr-28'>{children}</div>
            </div>
            <div className='hidden md:flex md:justify-center'>
                <img
                    src='/images/login.png?v=5'
                    className='block h-auto w-full max-w-[520px]'
                />
            </div>
        </main>

        <footer>
            <div className='f-full mx-auto flex max-w-[1400px] flex-col items-center justify-between gap-8 px-4 pt-4 pb-8 md:flex-row md:items-end'>
                <ul className='flex flex-col gap-4 text-sm md:flex-row xl:gap-0 xl:divide-x xl:divide-solid xl:divide-black'>
                    <li className='xl:pr-4'>
                        VCSW &copy; {new Date().getFullYear()}
                    </li>
                    <li className='xl:px-4'>
                        <a
                            className='hover:text-primary-500 transition-colors ease-in-out'
                            href='https://vcsw.nl/wp-content/uploads/2022/11/Privacy-statement-VCSW.pdf'
                            target='_blank'
                            rel='noreferrer'
                        >
                            Privacy Statement
                        </a>
                    </li>
                    <li className='xl:px-4'>
                        <a
                            className='hover:text-primary-500 transition-colors ease-in-out'
                            href='https://vcsw.nl/wp-content/uploads/2022/11/20102022_VCSW_Algemene-voorwaarden_2022_Oktober.pdf'
                            target='_blank'
                            rel='noreferrer'
                        >
                            Algemene voorwaarden
                        </a>
                    </li>
                    <li className='xl:px-4'>
                        <a
                            className='hover:text-primary-500 transition-colors ease-in-out'
                            href='mailto:info@vcsw.nl'
                        >
                            E: info@vcsw.nl
                        </a>
                    </li>
                    <li className='xl:pl-4'>
                        <a
                            className='hover:text-primary-500 transition-colors ease-in-out'
                            href='tel:0702600156'
                        >
                            T: 070 - 26 00 156
                        </a>
                    </li>
                </ul>
                <ul className='flex flex-col items-center gap-8 lg:flex-row'>
                    <li>
                        <img
                            src='/images/iso-27001-certificate-logo.png'
                            className='block h-20 w-auto rounded-s-full'
                        />
                    </li>
                </ul>
            </div>
        </footer>
    </div>
);

export default LoginLayout;
